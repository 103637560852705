import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createGlobalStyle, ThemeProvider} from 'styled-components';

const theme = {
  primary: "#ff3366",
  primaryBright: '#bf264c',
  black: "#252525",
  white: "#FFF",
  lightGray: "#efefef",
  baseFont: "'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
  headingFont: "'Roboto Mono', 'Courier New', Courier, monospace",
  buttonFont: "'Roboto Mono', 'Courier New', Courier, monospace",
  boxShadow: "1px 1px 10px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: "2rem"
};

const GlobalStyling = createGlobalStyle`
  *,*::before,*::after{box-sizing:border-box}ul[class],ol[class]{padding:0}body,h1,h2,h3,h4,p,ul[class],ol[class],figure,blockquote,dl,dd{margin:0}body{min-height:100vh;scroll-behavior:smooth;text-rendering:optimizeSpeed;line-height:1.5}ul[class],ol[class]{list-style:none}a:not([class]){text-decoration-skip-ink:auto}img{max-width:100%;display:block}article>*+*{margin-top:1em}input,button,textarea,select{font:inherit}img:not([alt]){filter:blur(10px)}@media(prefers-reduced-motion:reduce){*{animation-duration:.01ms !important;animation-iteration-count:1 !important;transition-duration:.01ms !important;scroll-behavior:auto !important}}

  body {
    margin: 0;
    font-family: ${props => props.theme.baseFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${props => props.theme.primary};
    height: 100vh;

    @media all and (display-mode: standalone) {
      position: fixed;
    }
  }

  #root {
    width: 100vw;
    height: 100vh;
  }
`

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyling />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: async registration => {
//     // We want to run this code only if we detect a new service worker is
//     // waiting to be activated.
//     // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//     if (registration && registration.waiting) {
//       await registration.unregister();
//       // Makes Workbox call skipWaiting()
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//       // Once the service worker is unregistered, we can reload the page to let
//       // the browser download a fresh copy of our app (invalidating the cache)
//       window.location.reload();
//     }
//   },
// });
// serviceWorker.register({
//   onUpdate: (registration) => {
//     if (registration.waiting) {
//       registration.waiting.postMessage({ type: "SKIP_WAITING" });
//       window.location.reload(true);
//     }
//   }
// });