import React, { Component } from 'react';
import InstallApp from './InstallApp/InstallApp'
import ReactGA from 'react-ga';


 
export class App extends Component {
  constructor (props) {
    super(props);

    ReactGA.initialize('UA-91006281-2');
    ReactGA.set({ anonymizeIp: true });

    this.state = {
      isVisible: false,
      firstTime: true,
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    setTimeout(() =>
      ReactGA.event({
        category: 'Timer',
        action: 'Time on page',
        label: '30 seconds'
      }), 30000);

      if (localStorage.getItem('closedOnboarding') || localStorage.getItem('appVersion')) {
        this.setState({
          isVisible: true,
          firstTime: false
        })
      } else {
        this.setState({
          isVisible: true,
          firstTime: true
        })
      }
  }

  render() {
      return (
        <>
          <InstallApp isVisible={this.state.isVisible} firstTime={this.state.firstTime} />
        </>
      );
  }
}
 
export default App;