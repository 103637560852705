import React, { Component } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import {ReactComponent as Logo} from './Logo.svg';
import {ReactComponent as ScoochLogo} from './Scooch.svg';
import {ReactComponent as AppStore} from './AppStore.svg';
import {ReactComponent as GooglePlay} from './GooglePlay.svg';

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  flex-direction: column;
`

const BoxContainer = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: ${props => props.theme.lightGray};
  border-radius: 2rem 2rem;
  box-shadow: ${props => props.theme.boxShadow};
`

const AppTextContainer = styled.div`
  padding: 30px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: 800px) {
    padding: 40px 40px 30px;
  }
`

const AppContainer = styled.div`
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.white};
  border-radius: 2rem 2rem;
  box-shadow: ${props => props.theme.boxShadow};

  @media only screen and (min-width: 800px) {
    padding: 40px;
  }
`

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  @media only screen and (min-width: 800px) {
    padding: 50px 40px;
  }

`

const Heading = styled.h2`
  font-family: ${props => props.theme.headingFont};
  font-size: 1.2rem;
  color: ${props => props.theme.primary};
  margin-bottom: 5px;
  text-transform: uppercase;

  @media only screen and (min-width: 800px) {
    font-size: 1.2rem;
  }
`

const BottomContent = styled.div`
  margin-top: 20px;
  max-width: 300px;
  text-align: center;

  @media only screen and (min-width: 800px) {
    max-width: 400px;
  }
`

const BottomContainer = styled.div`
  font-size: 0.8rem;
`

const SmallParagraph = styled.p`
  margin-top: 20px;
  font-size: 0.7rem;
  color: #752e28;
  font-style: italic;
`


const ChangeLanguage = styled.p`
  padding-top: 20px;
  display: block;
  font-size: 0.7rem;
  color: #752e28;
  text-decoration: none;
  font-family: ${props => props.theme.headingFont};
  text-transform: uppercase;
  font-weight: 600;
`


const LogoLink = styled.a`
  color: #752e28;

  &:hover {
    color: #171717;
  }
`

const StyledScoochLogo = styled(ScoochLogo)`
  width: 100%;
  height: auto;
  max-width: 100px;
  margin: 10px 0 30px;

  @media only screen and (min-width: 800px) {
    max-width: 100px;
  }
`

const TextContainer = styled.div`
  text-align: center;
  max-width: 450px;
  font-size: 1rem;

  @media only screen and (min-width: 800px) {
    font-size: 1.2rem;
  }
`

const Paragraph = styled.p`
  margin-bottom: 0;
`

const StyledLogo = styled(Logo)`
  color: ${props => props.theme.white};
  width: 100%;
  height: auto;
  max-width: 200px;
  margin: 20px 0 30px;

  @media only screen and (min-width: 800px) {
    max-width: 250px;
  }
`

const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;

  @media only screen and (min-width: 800px) {
    flex-direction: row;
  }
`

const NoBreakSpan = styled.span`
  white-space: nowrap;
`

const BadgeLink = styled.a`
  padding: 10px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`

const StyledAppStore = styled(AppStore)`
  height: 40px;

  @media only screen and (min-width: 800px) {
    height: 50px;
  }
`


const StyledGooglePlay = styled(GooglePlay)`
  height: 40px;

  @media only screen and (min-width: 800px) {
    height: 50px;
  }
`

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (min-width: 800px) {
    flex-direction: row;
  }
`


const ListItem = styled.li`
  padding: 2px 0;
`


const Link = styled.a`
  color: #562420;
  text-decoration: none;

  &:hover {
    color: #171717;
  }
`


const FirstTime = (props) => {
  return (
    <>
    <Box>
      <StyledLogo />
      <BoxContainer>
        <InformationContainer>
          <TextContainer>
            <Paragraph>Felsparkerad gör det enklare att rapportera felparkerade och hindrande sparkcyklar.</Paragraph>
          </TextContainer>
        </InformationContainer>
        <AppContainer>  
          <Heading>Ladda ner appen</Heading>
          <Paragraph>Felsparkerad finns tillgänglig utan kostnad <NoBreakSpan>i App Store och på Google Play</NoBreakSpan>.</Paragraph>
          <BadgeContainer>
          <BadgeLink href="http://appstore.com/felsparkerad">
            <StyledAppStore />
          </BadgeLink>
          <BadgeLink href="https://play.google.com/store/apps/details?id=com.felsparkerad.felsparkerad">
            <StyledGooglePlay />
          </BadgeLink>
          </BadgeContainer>
        </AppContainer>
      </BoxContainer>
      <BottomContent>
        <BottomContainer>
          <List>
            <ListItem><Link href="mailto:info@felsparkerad.se">info@felsparkerad.se</Link></ListItem>
            <ListItem><Link href="/terms.html">Terms &amp; Conditions</Link></ListItem>
            <ListItem><Link href="/privacy-policy.html">Privacy Policy</Link></ListItem>
          </List>
          <SmallParagraph>Tjänsten drivs <i>inte</i> på uppdrag av någon stad eller någon av de aktörer som tillhandahåller sparkcyklar.</SmallParagraph>
          <ChangeLanguage>A part of:</ChangeLanguage>
          <LogoLink href="https://scooch.io"><StyledScoochLogo /></LogoLink>
        </BottomContainer>
      </BottomContent>
    </Box>
    </>
  );
}

const UpdateApp = (props) => {
  return (
    <Box>
      <StyledLogo />
      <BoxContainer>
      <AppTextContainer>  
        <Heading>Nu som app!</Heading>
        <Paragraph>Felsparkerad finns nu tillgänglig utan kostnad <NoBreakSpan>i App Store och i Google Play</NoBreakSpan>.</Paragraph>
        <BadgeContainer>
        <BadgeLink href="http://appstore.com/felsparkerad">
          <StyledAppStore />
        </BadgeLink>
        <BadgeLink href="https://play.google.com/store/apps/details?id=com.felsparkerad.felsparkerad">
          <StyledGooglePlay />
        </BadgeLink>
        </BadgeContainer>
        </AppTextContainer>
      </BoxContainer>
      <BottomContent>
      <BottomContainer>
        <List>
          <ListItem><Link href="mailto:info@felsparkerad.se">info@felsparkerad.se</Link></ListItem>
          <ListItem><Link href="/terms.html">Terms &amp; Conditions</Link></ListItem>
          <ListItem><Link href="/privacy-policy.html">Privacy Policy</Link></ListItem>
        </List>
        <SmallParagraph>Tjänsten drivs <i>inte</i> på uppdrag av någon stad eller någon av de aktörer som tillhandahåller sparkcyklar.</SmallParagraph>
        <ChangeLanguage>A part of:</ChangeLanguage>
        <LogoLink href="https://scooch.io"><StyledScoochLogo /></LogoLink>
      </BottomContainer>
    </BottomContent>
    </Box>
  );
}

export class Information extends Component {
  constructor(props) {
    super(props);

    this.handleModalVisible = this.handleModalVisible.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleModalVisible = () => {
    if(this.props.isVisible) {
      ReactGA.modalview('/installApp');
    }
  };

  handleClose = () => {

  }

  render() {
    return (
    <>

    {this.props.firstTime ?
      <FirstTime isVisible={this.props.isVisible} handleModalVisible={this.handleModalVisible} onClose={this.handleClose} />
      :
      <UpdateApp isVisible={this.props.isVisible} handleModalVisible={this.handleModalVisible} onClose={this.handleClose} />
    } 
    </>
    );
  }
}
 
export default Information;
